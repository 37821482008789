import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Contact from '../components/Contact'

export default ({ location, data, pageContext }) => (
  <Layout
    pageTitle={data.allContentfulPageContact.edges[0].node.pageTitle}
    location={location}
    locale={pageContext.locale}
  >
    <Contact
      data={data.allContentfulPageContact.edges[0].node}
      locale={pageContext.locale}
    />
  </Layout>
)

export const contactQuery = graphql`
  query($locale: String) {
    allContentfulPageContact(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          pageTitle
          owner {
            childMarkdownRemark {
              html
            }
          }
          contact {
            childMarkdownRemark {
              html
            }
          }
          officeHours {
            childMarkdownRemark {
              html
            }
          }
          credits {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
