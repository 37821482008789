import React from 'react'
import { Link } from 'gatsby'

import * as El from './styles'
import form_strings from '../../constants/form'

const Contact = ({ data, locale }) => (
  <El.Container>
    {Object.keys(data).map(
      (el, i) =>
        el !== 'pageTitle' ? (
          <div
            key={i}
            dangerouslySetInnerHTML={{
              __html: data[el].childMarkdownRemark.html
            }}
          />
        ) : null
    )}
    <div />
    <div />
    <div />
    <div>
      <p>
        <Link
          to={locale === 'de' ? '/datenschutzerklarung' : '/en/privacy_policy'}
        >
          {form_strings[locale].buttons.privacy_policy}
        </Link>
      </p>
    </div>
  </El.Container>
)

export default Contact
